<template>
	<el-page-header
	    content="添加数据"
	    icon=""
	    title="页面管理"
	/>
	<el-form
	    ref="lxwmFormRef"
	    :model="lxwmForm"
	    :rules="lxwmFormRules"
	    label-width="80px"
	    class="demo-ruleForm"
	>
	    <el-form-item
	        label="内容"
	        prop="content"
	    >
		<editor @event="handleChange"/>
	    </el-form-item>
		<el-form-item>
		    <el-button
		        type="primary"
		        @click="submitForm()" 
		    >添加数据</el-button>
		</el-form-item>
	</el-form>
</template>

<script setup>
	import { ref, reactive } from "vue"
	import editor from '@/components/editor/Editor'
	import {useRouter} from 'vue-router'
	import axios from 'axios'
	const router = useRouter()
	const lxwmFormRef = ref();
	const lxwmForm = reactive({
	  content: "",
	  // file: null,
	});
	const lxwmFormRules = reactive({
	 content: [{ required: true, message: "请输入内容", trigger: "blur" }],
	});
	// 文本数据传输
	const handleChange = data => {
	  lxwmForm.content = data;
	};
	const submitForm = ()=>{
	    lxwmFormRef.value.validate(async (valid)=>{
	        if(valid){
	            //后台通信,
	          let req = await axios.post("/adminapi/lxwm/add",lxwmForm)
	            router.push(`/lxwm/lxwmlist`)
	        }
	    })
	}
</script>

<style lang="scss" scoped>
.demo-ruleForm {
  margin-top: 50px;
}
</style>