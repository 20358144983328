<template>
  <router-view/>
</template>

<style lang="scss">
*{
	margin: 0;
	padding: 0;
}
::-webkit-scrollbar{
	width: 5px;
	position: absolute;
}
::-webkit-slider-thumb{
	background: #1890ff;
}
::-webkit-scrollbar-track{
	background: #ddd;
}
</style>
