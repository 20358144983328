<template>
	<el-page-header content="首页" icon="" title="后台管理系统" />
	<el-card class="box-card">
		<el-row>
			<el-col :span="4">

				<el-avatar :size="100" :src="avatarUrl" @click="centerDialogVisible = true" />
			</el-col>
			<el-col :span="20">
				<h3 style="line-height:100px;">欢迎 {{store.state.userInfo.username}} 回来 {{welcomeText}}
				</h3>
			</el-col>
		</el-row>
	</el-card>
	<!-- 弹出框 -->
	<el-dialog v-model="centerDialogVisible" title="个人中心" width="60%" center>
		<span>
			<el-row :gutter="20" class="el-row">
				<el-col :span="8">
					<el-card class="box-card">
						<el-avatar :size="100" :src="avatarUrl" />
						<h3>{{store.state.userInfo.username}}</h3>
						<h5>{{store.state.userInfo.role===1?'超级管理员':'管理员'}}</h5>
					</el-card>
				</el-col>
				<el-col :span="16">
					<el-card>
						<template #header>
							<div class="card-header">
								<span>个人信息</span>

							</div>
						</template>
						<el-form ref="userFormRef" :model="userForm" :rules="userFormRules" label-width="120px"
							class="demo-ruleForm">
							<el-form-item label="用户名" prop="username">
								<el-input v-model="userForm.username" />
							</el-form-item>
							<el-form-item label="头像" prop="avatar">
							<Upload :avatar="userForm.avatar" @kerwinchange="handleChange"/>
							</el-form-item>
						</el-form>
					</el-card>
				</el-col>
			</el-row>
		</span>
		<template #footer>
			<span class="dialog-footer">
				<el-button type="primary" @click="submitForm()">更新</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {useStore} from 'vuex'
	import axios from 'axios';
	import {ElMessage} from "element-plus";
	import {computed,onMounted,ref,reactive} from 'vue'
	import {Plus} from '@element-plus/icons-vue'
	import upload from "@/util/upload";
	import Upload from '@/components/upload/Upload'
	import {getCurrentInstance} from 'vue';
	const { proxy } = getCurrentInstance();
	const store = useStore()
	// 计算有无图片上传，无图片上传就采用默认图片
	const avatarUrl = computed(
		() =>
		store.state.userInfo.avatar ?
		proxy.$key + store.state.userInfo.avatar :
		`https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png`
	);
	// 时间提示语句
	const welcomeText = computed(() => new Date().getHours() < 12 ? '要开心每一天.' : '喝杯咖啡提提神吧.')
	// 表单信息搜集
	const centerDialogVisible = ref(false)
	const {username,avatar} = store.state.userInfo;
	const userFormRef = ref();
	const userForm = reactive({
		username,
		avatar,
		file: null
	});
	// 表单验证
	const userFormRules = reactive({
		username: [{
			required: true,
			message: "请输入名字",
			trigger: "blur"
		}],
		avatar: [{
			required: true,
			message: "请上传头像",
			trigger: "blur"
		}]
	});
	//每次选择完图片之后的回调
	const handleChange = file => {
		userForm.avatar = URL.createObjectURL(file);
		userForm.file = file;
	};
	//更新提交
	const submitForm = () => {
	  userFormRef.value.validate(async valid => {
	    if (valid) {
	      // console.log("submit",userForm)
	      const res = await upload("/adminapi/user/upload", userForm);
	      if (res.ActionType === "OK") {
	        store.commit("changeUserInfo", res.data);
	        ElMessage.success("更新成功");
	      }
	    }
	  });
	};
</script>

<style lang="scss" scoped>
	.box-card {
		margin-top: 50px;
	}

	.dialog-footer button:first-child {
		margin-right: 10px;
	}

	.el-row {
		margin-top: 20px;

		.box-card {
			text-align: center;
		}
	}

	::v-deep .avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		transition: var(--el-transition-duration-fast);
	}

	::v-deep .avatar-uploader .el-upload:hover {
		border-color: var(--el-color-primary);
	}

	::v-deep .el-icon.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
	}
</style>