<template>
    <div>
        <el-page-header
            content="添加数据"
            icon=""
            title="新闻中心"
        />

        <el-form
            ref="newsFormRef"
            :model="newsForm"
            :rules="newsFormRules"
            label-width="80px"
            class="demo-ruleForm"
        >
            <el-form-item
                label="名称"
                prop="newsname"
            >
                <el-input v-model="newsForm.newsname" />
            </el-form-item>
            <el-form-item
                label="简介"
                prop="newstxt"
            >
                <el-input
                    v-model="newsForm.newstxt"
                    type="textarea"
                />
            </el-form-item>
			<el-form-item
			    label="内容"
			    prop="content"
			>
			<editor @event="handleChange2"/>
			</el-form-item>
            <el-form-item
                label="图片"
                prop="avatar"
            >
                <Upload
                    :avatar="newsForm.avatar"
                    @kerwinchange="handleChange"
                />
            </el-form-item>

            <el-form-item>
                <el-button
                    type="primary"
                    @click="submitForm()"
                >添加新闻</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script setup>
import { ref, reactive } from "vue"
import editor from '@/components/editor/Editor'
import Upload from "@/components/upload/Upload"
import upload from '@/util/upload'
import {useRouter} from 'vue-router'
import axios from 'axios'
const newsFormRef = ref()
const newsForm = reactive({
  newsname: "",
  content:"",
  newstxt: "",
  avatar: "",
  file: null,
});

const newsFormRules = reactive({
  newsname: [{ required: true, message: "请输入名称", trigger: "blur" }],
  content: [{ required: true, message: "请输入内容", trigger: "blur" }],
  newstxt: [{ required: true, message: "请输入简介", trigger: "blur" }],
  avatar: [{ required: true, message: "请上传图片", trigger: "blur" }]
});
// 文本数据传输
	const handleChange2 = data => {
	  newsForm.content = data;
	};
//每次选择完图片之后的回调
const handleChange = file => {
  newsForm.avatar = URL.createObjectURL(file);
  newsForm.file = file;
};
const router = useRouter()
const submitForm = ()=>{
    newsFormRef.value.validate(async (valid)=>{
        if(valid){
            //提交数据到后端
            await upload("/adminapi/news/add",newsForm)
            router.push(`/news/newslist`)
        }
    })
}
</script>

<style lang="scss" scoped>
.demo-ruleForm {
  margin-top: 50px;
}
</style>
