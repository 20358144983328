<template>
    <div>
	   <el-page-header
	       content="数据列表"
	       icon=""
	       title="数据管理"
	   />
        <el-form
            ref="lxwmFormRef"
            :model="lxwmForm"
            :rules="lxwmFormRules"
            label-width="80px"
            class="demo-ruleForm"
        >
            <el-form-item
                label="内容"
                prop="content"
            >
            <editor @event="handleChange" :content="lxwmForm.content" v-if="showEditor"/>
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    @click="submitForm()"
                >更新数据</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script setup>
import { ref, reactive,onMounted } from "vue";
import editor from '@/components/editor/Editor'
import {useRouter,useRoute} from 'vue-router'
import axios from 'axios'
import {getCurrentInstance} from 'vue';
const { proxy } = getCurrentInstance();
const showEditor = ref(false)
const router = useRouter()
const route = useRoute()
const lxwmFormRef = ref();
const lxwmForm = reactive({
  content: "",
});

const lxwmFormRules = reactive({
  content: [{ required: true, message: "请输入内容", trigger: "blur" }],
});

//每次editor内容改变的回调
const handleChange = data => {
  // console.log(data)
 lxwmForm.content = data;
};
const submitForm = ()=>{
   lxwmFormRef.value.validate(async (valid)=>{
        if(valid){
            //后台通信,
            await axios.put("/adminapi/lxwm/list",lxwmForm)
        }
    })
}

//获取当前页面数据
onMounted(async ()=>{
   const res = await  axios.get(`/adminapi/lxwm/list/`)
     console.log(res.data)
	 Object.assign(lxwmForm,res.data.data[0])
      if (res.data.data.length > 0) {
        Object.assign(lxwmForm, res.data.data[0]);
        showEditor.value = true; 
      }
})
</script>
<style lang="scss" scoped>
.el-form {
  margin-top: 50px;
}
</style>
